<script lang="ts">
	import type { PageData } from './$types';
	import { browser } from '$app/environment';
	import { page } from '$app/stores';
	import Page from '$components/Page/Page.svelte';
	import { tick } from 'svelte';

	// Component properties
	export let data: PageData;
</script>

<!--
  @component
  A page route component. Triggered automatically by SvelteKit for each page
  request.
  ```
 -->
<Page page={data.page} />
